<template>
	<v-layout fill-height column>
		<v-flex scroll-y>
			<WSection :title="$t('security.title') + ' :'">
				<v-layout column>
					<v-form ref="form">
						<v-text-field
							v-model="password"
							:append-icon="show ? 'visibility_off' : 'visibility'"
							:label="$t('security.fields.password')"
							:prepend-icon="'lock'"
							:rules="[rules.required, rules.webdavCompliant]"
							:type="show ? 'text' : 'password'"
							required
							@click:append="show = !show"
						/>

						<v-text-field
							v-model="passwordVerif"
							:append-icon="showVerif ? 'visibility_off' : 'visibility'"
							:label="$t('security.fields.password_confirmation')"
							:prepend-icon="'lock'"
							:rules="[rules.required, rules.webdavCompliant]"
							:type="showVerif ? 'text' : 'password'"
							required
							@click:append="showVerif = !showVerif"
							@keyup.enter="changePassword"
						/>
						<v-flex mx-auto shrink>
							<WButton :disabled="!canChangePassword" @click="changePassword">{{ $t('security.update_password') }} </WButton>
						</v-flex>
					</v-form>
				</v-layout>
			</WSection>
		</v-flex>
	</v-layout>
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'PasswordSetting',
	mixins: [AppModuleGuard],
	data: function () {
		return {
			password: '',
			passwordVerif: '',
			show: false,
			showVerif: false,
			rules: {}
		}
	},
	computed: {
		canChangePassword: function () {
			return (this.password + '').trim().length >= 1 && (this.passwordVerif + '').trim().length >= 1
		}
	},
	created () {
		this.rules = {
			required: (value = null) => {
				let result = true
				if (!value || value.trim() === '') {
					result = this.$t('signin.errors.missing')
				}
				return result
			},
			webdavCompliant: (value = '') => {
				let result = true
				if (value) {
					const forbiddenChars = this.appService.getWebDavUnSupportedChars()
					const hasUnsupportedChar = forbiddenChars.some(char => value.indexOf(char) !== -1)
					if (hasUnsupportedChar) {
						result = this.$t('signin.errors.webdavCompliant', { chars: forbiddenChars.join(', ') })
					}
				}
				return result
			}
		}
	},
	methods: {
		changePassword: function () {
			if (this.password.length < 8) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('security.errors.password_too_short'))
				return
			}
			if (this.password !== this.passwordVerif) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('security.errors.passwords_entry_differ'))
				return
			}
			if (!this.$refs.form.validate()) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('security.fields.rules'))
				return
			}
			return this.appService
				.changePassword(this.password, this.passwordVerif)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('profile.messages.password_updated'))
				})
				.catch(error => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
				})
		}
	}
}
</script>
